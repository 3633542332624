import React from "react";
import Layout from "../components/Layout";
import Blogs from "../components/Blogs";
import { graphql } from "gatsby";
import SEO from "../components/Seo";

const Blog = (
  {
    // data: {
    //   allStrapiBlogs: { nodes: blogs },
    // },
  }
) => {
  return (
    <Layout>
      <SEO
        title="Blog"
        description="A list of blog posts published by Belle Carrie"
      />
      <section className="blog-page">
        {/* <Blogs blogs={blogs} titleText="blog" /> */}
      </section>
    </Layout>
  );
};

// export const query = graphql`
//   {
//     allStrapiBlogs {
//       nodes {
//         image {
//           childImageSharp {
//             fluid {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         category
//         date(formatString: "MMMM Do YYYY")
//         desc
//         id
//         title
//         slug
//       }
//     }
//   }
// `;

export default Blog;
